import { isPlatformBrowser, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  NgZone,
  OnDestroy,
  OnInit,
  output,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { TranslatePipe } from 'translate';
import { sleep } from 'utils';

@Component({
  selector: 'lib-countdown',
  templateUrl: './countdown.component.html',
  imports: [NgClass, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnInit, OnDestroy {
  private platformId = inject(PLATFORM_ID);
  private ngZone = inject(NgZone);
  private cdr = inject(ChangeDetectorRef);
  countdownDateFromPrismic = input.required<string>();
  isOfferBoxLight = input(false);
  titleCss = input('!text-4xl !font-bold !text-center mb-0');
  textCss = input('!text-xs !text-center');
  showTexts = input(true);
  endDate!: Date;
  dateDiff!: Date;
  hide = signal(true);
  $timer: Subscription;
  countdownIsOver = output();

  async ngOnInit() {
    this.endDate = new Date(this.countdownDateFromPrismic());
    this.dateDiff = new Date(this.endDate.getTime() - new Date().getTime());

    if (isPlatformBrowser(this.platformId)) {
      // Cannot let this run on the server as it will then run indefinitely (and never return to the client)
      this.ngZone.runOutsideAngular(() => {
        this.$timer = timer(0, 1000).subscribe(() => {
          const hideCountDown = new Date(this.countdownDateFromPrismic()).getTime() - new Date().getTime() <= 0;
          if (hideCountDown) {
            this.hide.set(true);
            this.countdownIsOver.emit();
          }
          this.dateDiff = new Date(this.endDate.getTime() - new Date().getTime());
          this.ngZone.run(() => this.cdr.markForCheck());
        });
      });

      await sleep(0);
      this.hide.set(false);
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.$timer?.unsubscribe();
  }

  get days(): number {
    return this.dateDiff.getUTCDate() - 1;
  }

  get hours(): number {
    return this.dateDiff.getUTCHours();
  }

  get minutes(): number {
    return this.dateDiff.getUTCMinutes();
  }

  get seconds(): number {
    return this.dateDiff.getUTCSeconds();
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
